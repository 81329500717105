var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "cyui-layout-wrapper"
  }, [_c('cyui-layout', {
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_vm.isCustomHeader ? _c('cyui-header', {
          attrs: {
            "title": _vm.routeMetaTitle
          },
          on: {
            "go-back": _vm.goBack
          }
        }) : _vm._e()];
      },
      proxy: true
    }, {
      key: "default",
      fn: function () {
        return [_c('div', {
          staticClass: "page"
        }, [_c('van-cell-group', {
          staticClass: "cell-group"
        }, [_c('van-cell', {
          attrs: {
            "title": "申请人",
            "value": _vm.applicationPerson || '-'
          }
        }), _c('van-cell', {
          attrs: {
            "title": "申请部门",
            "value": _vm.projectName || '-'
          }
        })], 1), _c('van-cell-group', {
          staticClass: "cell-group"
        }, [_c('van-cell', {
          attrs: {
            "title": "领用类型",
            "value": _vm.receiveTypeMap[_vm.receiveType] || '-'
          }
        }), _c('van-field', {
          staticClass: "vtextarea",
          attrs: {
            "type": "textarea",
            "readonly": "",
            "label": "申领原因",
            "rows": "3",
            "autosize": "",
            "input-align": "left"
          },
          model: {
            value: _vm.receiveReason,
            callback: function ($$v) {
              _vm.receiveReason = $$v;
            },
            expression: "receiveReason"
          }
        })], 1), _c('div', {
          staticClass: "list"
        }, _vm._l(_vm.productList, function (item, index) {
          return _c('div', {
            key: index,
            staticClass: "item"
          }, [_c('div', {
            staticClass: "title"
          }, [_c('span', [_vm._v("申请明细：第" + _vm._s(index + 1) + "条")])]), _c('van-cell-group', {
            staticClass: "cell-group"
          }, [_c('van-cell', {
            attrs: {
              "title": "资产属性",
              "value": _vm.productPropertyMap[item.productProperty] || '-'
            }
          }), _c('van-cell', {
            attrs: {
              "title": "资产名称",
              "value": item.productName || '-'
            }
          }), _c('van-cell', {
            attrs: {
              "title": "主规格",
              "value": item.standard || '-'
            }
          }), _c('van-cell', {
            attrs: {
              "title": "资产编号",
              "value": item.productNo || '-'
            }
          }), _c('van-cell', {
            attrs: {
              "title": "单位",
              "value": item.unit || '-'
            }
          }), _c('van-cell', {
            attrs: {
              "title": "申领仓库",
              "value": item.outSpaceName || '-'
            }
          }), _c('van-cell', {
            attrs: {
              "title": "库存数量",
              "value": item.stockNum || '-'
            }
          }), _c('van-cell', {
            attrs: {
              "title": "数量"
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function () {
                return [_c('van-stepper', {
                  attrs: {
                    "disabled": ""
                  },
                  model: {
                    value: item.receiveNum,
                    callback: function ($$v) {
                      _vm.$set(item, "receiveNum", $$v);
                    },
                    expression: "item.receiveNum"
                  }
                })];
              },
              proxy: true
            }], null, true)
          }), _c('van-cell', {
            attrs: {
              "title": "投放位置",
              "value": item.inSpaceName || '-'
            }
          })], 1)], 1);
        }), 0), _c('van-cell-group', {
          staticClass: "cell-group form"
        }, [_c('van-cell', {
          attrs: {
            "title": "上传附件"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('div', {
                staticClass: "uploadPanel"
              }, [_c('van-uploader', {
                attrs: {
                  "show-upload": false,
                  "deletable": false
                },
                model: {
                  value: _vm.stockInImgList,
                  callback: function ($$v) {
                    _vm.stockInImgList = $$v;
                  },
                  expression: "stockInImgList"
                }
              })], 1)];
            },
            proxy: true
          }])
        })], 1)], 1)];
      },
      proxy: true
    }])
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };