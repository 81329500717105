
  import common from '@mixins/common'
  import { mapGetters } from 'vuex'
  import _ from 'lodash'
  import * as assetsApi from '@api/assets'
  import moment from 'moment'
  import { getAllocateOutDetail } from '@api/assets'

  const productPropertyList = [
    {label: "易耗品", value: 1},
    {label: "低值品", value: 2},
    {label: "固定资产", value: 3},
  ]
  const productPropertyMap = _.mapValues(_.keyBy(productPropertyList, i=>i.value), j=>j.label);

  const receiveTypeList = [
    {label: "维修领用", value: 1},
    {label: "保洁领用", value: 2},
    {label: "办公使用", value: 3},
    {label: "客户维系", value: 4},
  ];
  const receiveTypeMap = _.mapValues(_.keyBy(receiveTypeList, i=>i.value), j=>j.label);

  export default {
    name: 'AssetsTakeLogDetail',
    mixins: [common],
    computed: {
      ...mapGetters('user', [
        'userInfo',
        'projectInfo',
      ])
    },
    data() {
      return {
        id: '',
        applicationPerson:'',
        projectName:'',
        receiveType:'',
        receiveReason:'',
        attachment:'',
        productList: [],

        receiveTypeMap,
        productPropertyMap,
        stockInImgList: [],
      }
    },
    created() {
      this.id = this.$route.query.id;
      this.getData();
    },
    methods: {
      getData(){
        assetsApi.getTakeDetail(this.id).then(res=>{
          let item = res.data.data;
          this.applicationPerson = item.applicationPerson;
          this.projectId = item.projectId;
          this.projectName = item.projectName;
          this.receiveReason = item.receiveReason;
          this.receiveType = item.receiveType;
          this.productList = item.takeDetailVos;
          if (item.fileList) {
            item.fileList.forEach(i=>{
              i.url = i.fileUrl;
            });
            this.stockInImgList = item.fileList;
          }
        })

      },
    }
  }
